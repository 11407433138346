.head_navbarmain{
    background-image: url("../../Assets/head_navbarmain.png");
    width: 100%;
height: 49px;
top: 238px;
left: -175px;
padding: 0px, 1611.27px, 0px, 0px;

}
/* .social-links {
  width: 100%;
  text-align: center;
} */
.social-links a {
  display: inline-block;
  /* height: 40px; */
  width: 40px;
  /* background-color: rgba(255,255,255,0.2); */
  /* margin: 0 10px 10px 0; */
  /* text-align: center; */
  line-height: 40px;
  /* border-radius: 50%; */
  color: #ffffff;
  /* transition: all 0.5s ease; */
}

 .social-links a:hover{
	color: red;
	
}

.container {
    text-align: justify;
  }
.start{
  color:#FFFFFF;
  font-family: Radio Canada;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.end{
  color:#FFFFFF;
  font-family: Radio Canada;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
float: right;
}
  .start, .end {
    display: inline-block;
  }

  .start::before {
    content: '\00a0'; /* Non-breaking space */
  }
@media screen and (max-width:1280px){
  .start{
    color:#FFFFFF;
    font-family: Radio Canada;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  .end{
    color:#FFFFFF;
    font-family: Radio Canada;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  float: right;
  }
}

@media screen and (max-width:1000px){
  .start{
    color:#FFFFFF;
    font-family: Radio Canada;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  .end{
    color:#FFFFFF;
    font-family: Radio Canada;
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  float: right;
  }
  .social-links a {
    display: inline-block;
    /* height: 40px; */
    width: 21px;
    /* background-color: rgba(255,255,255,0.2); */
    /* margin: 0 10px 10px 0; */
    /* text-align: center; */
    line-height: 40px;
    /* border-radius: 50%; */
    color: #ffffff;
    /* transition: all 0.5s ease; */
}
}

@media screen and (max-width:768px){
  .head_navbarmain{
    display: none;
  }
}
@media screen and (max-width:1440px){
 .content_navbar{
  padding-left: 90px !important;
 }
}

.navbar-brand {
  color: #ffffff;
  font-weight: bold;
}

.navbar-nav .nav-item .nav-link {
  font-family: Radio Canada;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
   color: #1B1B1B;
  position: relative;
  transition: 0.3s;
  transform: translateX(0);
  margin-right: 10px;
}
.navbar-dark .navbar-toggler-icon {
  background-color: #1B1B1B;
}
.navbar-nav .nav-item .nav-link:hover {
  transform: translateX(10px);
  /* Hover effect with a transform */
  color: #FF5E15;
}

.navbar-nav .nav-item .nav-link::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transition: 0.3s;
}

.navbar-nav .nav-item .nav-link:hover::before {
  width: 100%;

}
.nav-link:active {
  color: red !important;
  
}


.navbar-collapse .navbar-nav .nav-link:focus,

.navbar-collapse .navbar-nav .nav-link:active {
    color: red !important;
}
.contact_ushref{
  text-decoration: none !important;
  color: #ffffff;
}
.contact_ushref:hover{
  text-decoration: none !important;
  color: red;
}
