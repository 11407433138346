.main_testimonial{
  background-image: url("../../Assets/feedback.png");
  padding-bottom: 50px;
}
  .gtco-testimonials {
    position: relative;
  
 
  width: 100%;
  height: 587.45px;
padding-top: 30px;
  
  
    .owl-stage-outer {
      padding: 30px 0;
    }
  
    .owl-nav {
      display: none;
    }
  
    .owl-dots {
      text-align: center;
  
      span {
        position: relative;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: block;
        background: #fff;
        border: 2px solid #01b0f8;
        margin: 0 5px;
      }
  
      /* .active {
        box-shadow: none;
  
        span {
          background: #01b0f8;
          box-shadow: none;
          height: 12px;
          width: 12px;
          margin-bottom: -1px;
        }
      } */
    }
  
    .card {
      background: #fff;
      box-shadow: 0 8px 30px -7px #c9dff0;
      margin: 0 20px;
      padding: 0 10px;
      border-radius: 20px;
      border: 0;
  
      .card-img-top {
        max-width: 100px;
        border-radius: 50%;
        margin: 15px auto 0;
        box-shadow: 0 8px 20px -4px #95abbb;
        width: 100px;
        height: 100px;
      }
  
    
  
      p {
        font-size: 18px;
        color: #555;
        padding-bottom: 15px;
      }
    }
  
    /* .active {
      opacity: 0.5;
      transition: all 0.3s;
    } */
  
    .center {
      opacity: 1;
  
      h5 {
        font-size: 24px;
  
        span {
          font-size: 20px;
        }
      }
  
      .card-img-top {
        max-width: 100%;
        height: 120px;
        width: 120px;
      }
    }
  }
  
  @media (max-width: 767px) {
    .gtco-testimonials {
      margin-top: 20px;
    }
  }
  
  .owl-carousel {
    .owl-nav button {
      &.owl-next, &.owl-prev {
        outline: 0;
      }
    }
  
    button.owl-dot {
      outline: 0;
    }
  }

  .client_subhead{
color: #00235A;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: center;

  }
  .client_head{
    color: #00235A;
    font-family: Radio Canada;
font-size: 38px;
font-weight: 700;
line-height: 54px;
letter-spacing: 0em;
text-align: center;

  }


  .feedback_name{
    font-family: Radio Canada;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
color: #00235A;
}
  .feedback_desig{
    color: #FF5E15;
    font-family: Radio Canada;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0em;
text-align: center;

  }
  .feedback_content{
    color: #7E7E7E;
    font-family: Radio Canada;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: center;

  }