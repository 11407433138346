
  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter:wght@400;600&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter:wght@400;600&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&display=swap');
  
  @import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@1,700&family=Fira+Sans&family=Inter:wght@400;600&family=Poppins:wght@600&family=Radio+Canada:wght@400;500;600;700&family=Ubuntu&display=swap');

.dedicatedService_head{
    font-family: Radio Canada;
font-size: 38px;
font-weight: 700;
line-height: 54px;
letter-spacing: 0em;
text-align: center;
color:#00235A ;
}
.dedicatedService_content{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color:#00235A ; 

}

.container {
    text-align: center;
    margin-bottom: 20px;
  }
  

  
  .services-container {
    display: flex;
    /* justify-content: space-around; */
justify-content: space-evenly;
    flex-wrap: wrap;
  }
  
  .service {
    text-align: center;
    /* margin: 10px; */
  }
  
  .service-image {
    width: 150px; /* Adjust according to your preference */
    height: auto;
  }
  
  .service-description {
    font-size: 16px;
  }
  .selected {
    text-decoration: underline;
    

    text-decoration-thickness: 3px;

text-decoration-color: #FF5E15;
  }
  .both_content{
    display: flex;
    justify-content: center;
    text-align: center;
  }
  /* .service_img{
    width: 100%;
    height: 100%;
  } */
  .discover_more{

    width: 151.11px;
height: 56px;
top: 1602px;
left: 835px;
padding: 0px, 17.11px, 0px, 17px;
border: 1px;
background-color:#FF5E15 ;
color: #ffffff;
  }
  .li_items{
    text-align: justify;
  }
  .content_name_Slider{
    font-family: Ubuntu;
font-size: 24px;
font-weight: 700;
line-height: 28px;
letter-spacing: 0em;
text-align: left;

color: #FF5E15;
  }
  .content_name_Slider_p{
    font-family: Ubuntu;
font-size: 18px;
font-weight: 400;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color:#373737 ;
  }
  .dis_text{
    text-align: start;
  }